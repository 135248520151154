import React from 'react';

const Wrapper = (props) => {
  return (
    <div
      className={`${props.className && props.className} ${
        props.fluid ? 'w-full' : 'mx-auto max-w-screen-xl'
      }`}>
      {props.children}
    </div>
  );
};

export default Wrapper;
