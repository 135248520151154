import React from 'react';
import { graphql } from 'gatsby';

import Intro from '../components/intro/Intro';
import Divider from '../components/divider/divider';
import Wrapper from '../components/wrapper/wrapper';
import {
  ClipboardListIcon,
  CubeTransparentIcon,
  ColorSwatchIcon,
  PencilAltIcon,
  ScaleIcon,
  ClockIcon,
} from '@heroicons/react/outline';

import gfx from './../images/gfx/88Z_furniture_original.svg';
import IndexLayout from '../layouts';
import Header from '../components/headers/headers';

const JakDzialam = ({ data }) => {
  const howWorks = [
    {
      id: 1,
      title: 'Określenie Twoich potrzeb',
      text: 'Na pierwszym spotkaniu omawiamy jakiego rodzaju meble potrzebujesz.',
      icon: <ClipboardListIcon className='h-12 w-12 stroke-1 text-myOrange' />,
    },
    {
      id: 2,
      title: 'Pomiar',
      text: 'Mierzę powierzchnie przeznaczone pod meble.',
      icon: (
        <CubeTransparentIcon className='h-12 w-12 stroke-1 text-myOrange' />
      ),
    },
    {
      id: 3,
      title: 'Dobór materiałów.',
      text: 'Wspólnie dobieramy materiały, wykończenia i rozwiązania techniczne.',
      icon: <ColorSwatchIcon className='h-12 w-12 stroke-1 text-myOrange' />,
    },
    {
      id: 4,
      title: 'Wycena',
      text: 'Wysyłam wycenę, uwzględniając założony budżet i rozwiązania.',
      icon: <PencilAltIcon className='h-12 w-12 stroke-1 text-myOrange' />,
    },
    {
      id: 5,
      title: 'Indywidualne warunki',
      text: 'Ustalamy kwestię zaliczek i końcową płatność za produkcję i montaż.',
      icon: <ScaleIcon className='h-12 w-12 stroke-1 text-myOrange' />,
    },
    {
      id: 6,
      title: 'Termin realizacji',
      text: 'Ustalamy termin realizacji zlecenia z uwzględnieniem czasu dostaw elementów.',
      icon: <ClockIcon className='h-12 w-12 stroke-1 text-myOrange' />,
    },
  ];

  return (
    <IndexLayout>
      <main>
        <Intro image={data.file} />
        <Divider />
        <Wrapper className='flex flex-col'>
          <Header size='h2' className='text-myOrange'>
            Jak działam?
          </Header>
          <ul className='grid grid-cols-1 content-center gap-y-10 py-12 align-middle md:grid-cols-2 lg:grid-cols-3'>
            {howWorks.map(({ id, title, text, icon }) => {
              return (
                <li className='relative flex h-60 max-w-[300px] flex-col items-center justify-start place-self-center bg-white p-4 shadow-md'>
                  <span className='absolute -top-6 -left-6 flex h-12 w-12 items-center justify-center rounded-full bg-myOrange text-2xl font-bold text-white'>
                    {id}
                  </span>
                  {icon}
                  <h3 className='my-4 text-lg font-bold'>{title}</h3>
                  <p className='text-center'>{text}</p>
                </li>
              );
            })}
          </ul>
          <img src={gfx} alt='Ludzie pracujący przy montażu' />
        </Wrapper>
      </main>
    </IndexLayout>
  );
};

export const query = graphql`
  {
    file(name: { eq: "hello" }) {
      id
      childImageSharp {
        # gatsbyImageData(placeholder: BLURRED, quality: 90)
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export default JakDzialam;

export function Head() {
  return (
    <>
      <meta charSet='UTF-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta
        name='description'
        content='Meble biurowe, kuchenne, szafy, zabudowy. Wszystko na wymiar. Koszalin i okolice. Ponad 25 lat doświadczenia'
      />
      <title>Stromich - meble na wymiar. Jak działam.</title>
    </>
  );
}
