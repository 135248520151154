import React from 'react';

const Header = ({ size, children, className }) => {
  let element;
  switch (size) {
    case 'h1':
      element = (
        <h1 className='py-8 text-center text-5xl font-bold text-myOrange'>
          {children}
        </h1>
      );
      break;
    case 'h2':
      element = (
        <h2
          className={`p-4 text-center text-3xl font-bold md:text-4xl ${className}`}
        >
          {children}
        </h2>
      );
      break;
    case 'h3':
      element = (
        <h3
          className={`mb-6 text-center text-3xl font-bold  md:text-left ${className}`}
        >
          {children}
        </h3>
      );
      break;

    default:
      console.log(`Sorry, we are out of ${size}.`);
  }

  return element;
};

export default Header;
